






























































































































































































































































































































































import BindChannelByCode, { BindChannelState } from "@/components/chat/BindChannelByCode.vue";
import { CHATKEEPER_BOT_LOGIN } from "@/includes/constants";
import { InputSetups } from "@/mixins/input-setups";
import TariffsTagsHelper from "@/mixins/TariffsTagsHelper";
import NotAvailableOptionsOverlay from "@/components/NotAvailableOptionsOverlay.vue";
import { ChatConfigSettings } from "@/includes/types/Chat/types";
import { successNotification } from "@/includes/NotificationService";
import { ReasonType } from "@/views/chat/journal/types";

import PageTitle from "piramis-base-components/src/components/PageTitle.vue";
import SwitchInput from "piramis-base-components/src/components/Pi/fields/SwitchInput/SwitchInput.vue";
import { atSignedLogin } from "piramis-base-components/src/shared/utils/tgLoginHelpers";
import HighlightAnchor from "piramis-base-components/src/components/HighlightAnchor.vue";
import ConfigField from "piramis-base-components/src/components/ConfigField/ConfigField.vue";
import SelectInput from "piramis-base-components/src/components/Pi/fields/SelectInput/SelectInput.vue";
import { SelectOptionData } from "piramis-base-components/src/components/Pi/types";

import { Component, Emit, Mixins, Prop, VModel } from 'vue-property-decorator';
import { snakeCase } from "lodash";

@Component({
  components: {
    NotAvailableOptionsOverlay,
    BindChannelByCode,
    SwitchInput,
    PageTitle,
    HighlightAnchor,
    SelectInput,
    ConfigField
  }
})
export default class JournalSettings extends Mixins(InputSetups, TariffsTagsHelper) {

  @VModel() model!: ChatConfigSettings['common_config']

  @Prop({ type: Boolean, default: false, required: false }) disabled!: boolean

  @Emit()
  saveButtonClick() {
    return true
  }

  selectedCommonOptions: Array<ReasonType> = []

  selectedCommandOptions: Array<ReasonType> = []

  JOURNAL_COMMAND_KEYS: Array<ReasonType> = [
    'CommandOn',
    'CommandOff',
    'CommandRooff',
    'CommandKickAll',
    'CommandKickAllNew',
    'CommandKickAllOff',
    'CommandRo',
    'CommandDellog',
    'CommandRoall',
    'CommandRepin',
    'CommandUnrepin',
    'CommandResetfilters',
    'CommandBotWladdadmin',
    'CommandBotWldeladmin',
    'CommandBotaddadmin',
    'CommandBotdeladmin',
    'CommandSiteaddadmin',
    'CommandSitedeladmin',
  ]

  JOURNAL_COMMON_KEYS: Array<ReasonType> = [
    'UserbotDetected',
    'ReturnNewMember',
    'EnterNewMember',
    'UpdateSettings',
    'UpdateModuleSettings',
    'UpdateTrigger',
    'UpdateActiveTriggers',
    'TriggerCall',
    'TriggerMessageDelete',
    'UserProfileChanged',
    'AnotherBotPunishUsers',
    'MemberKicked',
    'MemberLeave',
    'MemberRestricted',
    'UnbannedMemberRestricted',
    'LeaveMemberRestricted',
    'AdminPermissions',
    'CommentSpamTimeout',
    'CommentCloseTimeout',
    'UserCompleteCaptcha',
    'CaptchaNotVerifiedKick',
    'RemoveUserByMathCaptcha',
  ]

  handleOnChannelBind(bindData: BindChannelState): void {
    if (bindData.channel_id) {
      this.$store.dispatch('requestChatConfig', { id: this.$store.getters.chatId })
        .then(() => {
          successNotification(this.$t('journal_channel_bind_success_message').toString())
        })
    }
  }

  get translationParams() {
    const brandBotName = this.$store.state.chatState.chat?.brand?.botname

    return brandBotName ? [ atSignedLogin(brandBotName) ] : [ atSignedLogin(CHATKEEPER_BOT_LOGIN) ]
  }

  getTagIfLimited(key: string) {
    const advanced = [
      'TriggerCall',
      'TriggerMessageDelete',
      'MemberKicked',
      'MemberLeave',
      'MemberRestricted'
    ]

    if (advanced.includes(key)) {
      return this.advancedLicenseTag
    }

    return null
  }

  generateJournalOptions(keys: Array<string>): Array<SelectOptionData> {
    return keys.map(k => {
      const tariffTag = this.getTagIfLimited(k)

      return {
        label: this.$t(`journal_log_${ snakeCase(k) }`).toString(),
        value: k,
        tags: tariffTag,
        disabled: !!tariffTag
      }
    })

  }

  get journalCommandOptions() {
    return this.generateJournalOptions(this.JOURNAL_COMMAND_KEYS)
  }

  get journalCommonOptions(): Array<SelectOptionData> {
    return this.generateJournalOptions(this.JOURNAL_COMMON_KEYS)
  }

  loadExcludeJournalKeys() {
    if (Array.isArray(this.$store.state.chatState.chat?.common_config?.journal_exclude)) {
      const excludeJournal = this.$store.state.chatState.chat?.common_config?.journal_exclude ?? []

      const oldJournalEvents = [ 'TriggerTriggerWarn', 'UserPropTransition' ]
      this.selectedCommonOptions = excludeJournal.filter((k: ReasonType) => [ ...this.JOURNAL_COMMON_KEYS, ...oldJournalEvents ].includes(k))

      this.selectedCommandOptions = excludeJournal.filter((k: ReasonType) => this.JOURNAL_COMMAND_KEYS.includes(k))
    }
  }

  optionsChanged(_: Array<SelectOptionData>) {
    this.$store.commit('EXEC_CALLBACK_IN_STORE', {
      'callback': () => {
        if (this.$store.state.chatState.chat) {
          this.$store.state.chatState.chat.common_config.journal_exclude = [ ...this.selectedCommonOptions, ...this.selectedCommandOptions ]
        }
      }
    })
  }

  mounted() {
    this.$baseTemplate.saveButton.show()

    this.loadExcludeJournalKeys()
  }
}
